<template>
  <div class="video">
    <div class="overlay">
      <div class="contact">
        <span>
          <a
            class="link"
            target="_blank"
            href="https://afisha.md/ro/events/afisha-recomanda/15470/sod-25-spring-mess"
            >tickets</a
          >
        </span>
        <span>
          <a class="link" @click="show = !show">contact</a>
          <transition name="fade">
            <p v-if="show">sound@olandeep.com</p>
          </transition>
        </span>
      </div>
      <div class="content">
        <div class="info">
          <ul class="socials">
            <li>
              <a
                href="https://www.facebook.com/OLANDEEP"
                class="link"
                target="blank"
              >
                facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/sound_of_olandeep"
                class="link"
                target="blank"
              >
                instagram
              </a>
            </li>
            <li>
              <a
                href="https://t.me/soundofolandeep"
                class="link"
                target="blank"
              >
                telegram
              </a>
            </li>
          </ul>
          <div class="logo">
            <img src="/logo.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
export default {
  setup() {
    const show = ref(false);
    return {
      show,
    };
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: Semi-Coder;
  src: url("../public/fonts/Semi-Coder-Regular.otf");
}
$red: #eef1e4;
$whitey: #f0f0f0;

$duration: 0.2s;
$distance: 8px;
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

html,
body {
  font-family: Semi-Coder;
}
.logo {
  padding-bottom: 60px;
  padding-top: 50px;
  @media only screen and (max-width: 500px) {
    padding-top: 20px;
  }
  img {
    height: 12px;
    @media only screen and (max-width: 500px) {
      height: 8px;
    }
  }
}
.contact {
  font-family: Semi-Coder;
  color: white;
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-top: 60px;
  p {
    font-size: 10px;
    cursor: pointer;
    height: 20px;
  }
  span {
    width: 100px;
  }
}
.buy {
  display: none;
  @media only screen and (max-width: 500px) {
    display: block;
    a {
      margin-top: 20px;
      box-shadow: inset 0px 1px 0px 0px #ffffff;
      background-color: #ffffff;
      border-radius: 6px;
      border: 1px solid #dcdcdc;
      display: inline-block;
      cursor: pointer;
      color: #666666;
      font-family: Arial;
      font-size: 15px;
      font-weight: bold;
      padding: 6px 10px;
      text-decoration: none;
      text-shadow: 0px 1px 0px #ffffff;
    }
    a:hover {
      background-color: #f6f6f6;
    }
    a:active {
      position: relative;
      top: 1px;
    }
  }
}
.overlay {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
}
.content {
  position: absolute;
  bottom: 20px;
  margin: auto;
  width: 100%;
  text-align: center;
}
.video {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background-color: black;
}
.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
#myvideo {
  background-color: black;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.link {
  cursor: pointer;
  font-family: Semi-Coder;
  font-size: 10px;
  color: white;
  position: relative;
  text-decoration: none;
  margin: 0;
  padding-block: 5px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $red;
  }
  &:before {
    opacity: 0;
    transform: translateY(-$distance);
    transition: transform 0s $easeOutBack, opacity 0s;
  }
  &:after {
    opacity: 0;
    transform: translateY($distance/2);
    transition: transform $duration $easeOutBack, opacity $duration;
  }
  &:hover,
  &:focus {
    &:before,
    &:after {
      opacity: 1;
      transform: translateY(0);
    }
    &:before {
      transition: transform $duration $easeOutBack, opacity $duration;
    }
    &:after {
      transition: transform 0s $duration $easeOutBack, opacity 0s $duration;
    }
  }
}
.socials {
  display: flex;
  justify-content: space-evenly;
  padding-left: 0;
  list-style: none;
  bottom: 0;
  z-index: 999;
  width: 100%;
  li {
    cursor: pointer;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
html,
body {
  height: 100%;
  margin: 0px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
